import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Grid,
  Box,
  AppBar,
  Toolbar,
  Typography,
  Slide,
  TextareaAutosize,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PhotoIcon from "@mui/icons-material/Photo";
import CollectionsIcon from "@mui/icons-material/Collections";
import apiHelper from "../../../Common/ApiHelper";
import { Toast } from "bootstrap";
import SnackbarComponent from "../../../Component/Snackbar/Sncakbar";

const AddScreenDialog = ({
  open,
  setOpen,
  operationMode,
  setOperationMode,
  editData,
}) => {


  const [Category, setCategory] = useState([]);
  const [base64, setBase64] = useState(null);
  const [Multibase64, SetMultibase64] = useState(null);
  const [selectedOption, setSelectedOption] = useState("content");
  const [IsSubmited, setIsSubmited] = useState(false);
  const [Error, setError] = useState([]);
  const [Snack_open, setSnack_Open] = useState(false);

  const [contentData, setContentData] = useState({
    title: "",
    subtitle: "",
    description: "",
    category: "",
    multiImages: Multibase64,
    singleImage: base64,
    type: selectedOption,
  });
  const [productData, setProductData] = useState({
    title: "",
    subtitle: "",
    category: "",
    description: "",
    price: "",
    discount: "",
    multiImages: Multibase64,
    singleImage: base64,
    type: selectedOption,
  });

  useEffect(() => {
    if (operationMode == "edit" && editData) {
      if (editData.type == "content") {
        setContentData({
          title: editData?.title || "",
          subtitle: editData?.subtitle || "",
          description: editData?.description || "",
          category: editData?.category?._id,
          multiImages: Multibase64,
          singleImage: base64,
          type: editData?.type,
        });
      } else {
        setProductData({
          title: editData?.title || "",
          subtitle: editData?.subtitle || "",
          description: editData?.description || "",
          category: editData?.category?._id,
          multiImages: Multibase64,
          singleImage: base64,
          price: editData?.price,
          description: editData?.discount,
          type: editData?.type,
        });
      }

      setSelectedOption(editData?.type == "content" ? "content" : "product");
      setBase64(editData?.singleImage);
      SetMultibase64(editData?.multiImages);
    }
  }, [operationMode]);

  const GetCategoryData = async () => {
    try {
      const result = await apiHelper.GetCategory();
      if (result && result.status === 200) {
        setCategory(result.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetCategoryData();
  }, []);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleContentChange = (e) => {
    setContentData({ ...contentData, [e.target.name]: e.target.value });
  };

  const handleProductChange = (e) => {
    setProductData({ ...productData, [e.target.name]: e.target.value });
  };

  const handleSingleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Keep the full data URL
      const dataURL = reader.result;
      // console.log(dataURL, "Data URL with base64");
      setBase64(dataURL);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      console.error("No file selected");
    }
  };

  const handleMultipleImagesUpload = (e, type) => {
    const files = e.target.files;
    const imageArray = [];
    const promises = [];

    Array.from(files).forEach((file) => {
      const reader = new FileReader();

      const promise = new Promise((resolve) => {
        reader.onloadend = () => {
          imageArray.push(reader.result);
          resolve();
        };
        reader.readAsDataURL(file);
      });

      promises.push(promise);
    });

    Promise.all(promises).then(() => {
      if (type === "content") {
        setContentData({ ...contentData, multiImages: imageArray });
      } else {
        setProductData({ ...productData, multiImages: imageArray });
      }

      SetMultibase64(imageArray);
    });
  };

  const handleRemoveImage = (index, type) => {
    let updatedImages;

    if (type === "content") {
      updatedImages = contentData?.images?.filter((_, i) => i !== index);
      setContentData({ ...contentData, multiImages: updatedImages });
    } else {
      updatedImages = productData?.images?.filter((_, i) => i !== index);
      setProductData({ ...productData, multiImages: updatedImages });
    }

    SetMultibase64(updatedImages);
  };

  const handleRemoveSingleImage = () => {
    setProductData({ ...productData, singleImage: "" });
  };

  const handleClose = () => {
    setOpen(false);
    setOperationMode("add");
    window.location.reload();
  };

  const handleSubmit = async () => {
    try {
      setIsSubmited(true);

      if (!contentData?.title && selectedOption === "content") {
        return alert("Please enter a title for content");
      }

      if (!productData?.title && selectedOption === "product") {
        return alert("Please enter a title for the product");
      }

      let finalData;

      if (selectedOption === "content") {
        finalData = {
          ...contentData,
          multiImages: Multibase64 || [],
          singleImage: base64 || "",
        };
      } else {
        finalData = {
          ...productData,
          multiImages: Multibase64 || [],
          singleImage: base64 || "",
        };
      }

      let result;

      if (operationMode == "edit") {
        result = await apiHelper.EditProductDetails(finalData, editData?._id);
      } else {
        result = await apiHelper.AddProduct(finalData);
      }

      if (result.status === 200) {
        setSnack_Open(true);
        setOpen(false);
        setContentData({});
        setProductData({});
        setBase64(null);
        SetMultibase64([]);
        setOperationMode("add");
      } else {
        alert("Submission failed: " + result.data.message);
      }
    } catch (error) {
      console.error("Error during submission:", error);
      alert("An error occurred during submission. Please try again.");
    }
  };

  return (
    <>
      <SnackbarComponent
        setSnack_Open={setSnack_Open}
        Snack_Open={Snack_open}
      />

      <Dialog
        sx={{ mt: 5 }}
        open={open}
        onClose={handleClose}
        // TransitionComponent={(props) => <Slide direction="up" {...props} />}
        maxWidth="md" // Set dialog width to medium
        fullWidth
        fullScreen
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {operationMode == "edit" ? "Edit" : "Add"}{" "}
              {selectedOption === "content" ? "Content" : "Product"}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <FormControlLabel
                value="content"
                control={<Radio />}
                label="Content"
              />
              <FormControlLabel
                value="product"
                control={<Radio />}
                label="Product"
              />
            </RadioGroup>
          </FormControl>

          <>
            <FormControl fullWidth margin="dense">
              <InputLabel>Select Category</InputLabel>
              <Select
                name="category"
                value={
                  selectedOption === "content"
                    ? contentData.category
                    : productData.category
                }
                onChange={
                  selectedOption === "content"
                    ? handleContentChange
                    : handleProductChange
                }
              >
                {Category &&
                  Category?.map((x) => (
                    <MenuItem value={x?._id}>{x?.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            {selectedOption === "content" ? (
              <TextField
                label="Title"
                name="title"
                fullWidth
                margin="dense"
                value={contentData?.title}
                onChange={handleContentChange}
              />
            ) : (
              <TextField
                label="Title"
                name="title"
                fullWidth
                margin="dense"
                value={productData?.title}
                onChange={handleContentChange}
              />
            )}
            <TextField
              label="Subtitle"
              name="subtitle"
              fullWidth
              margin="dense"
              value={
                selectedOption === "content"
                  ? contentData?.subtitle
                  : productData?.subtitle
              }
              onChange={
                selectedOption === "content"
                  ? handleContentChange
                  : handleProductChange
              }
            />
            <TextField
              label="Description"
              name="description"
              fullWidth
              margin="dense"
              placeholder="write description about your product or content"
              multiline
              minRows={3}
              value={
                selectedOption === "content"
                  ? contentData?.description
                  : productData?.description
              }
              onChange={
                selectedOption === "content"
                  ? handleContentChange
                  : handleProductChange
              }
            />
            {selectedOption === "product" && (
              <>
                <TextField
                  label="Product Price"
                  name="price"
                  fullWidth
                  margin="dense"
                  type="number"
                  value={productData?.price}
                  onChange={handleProductChange}
                />
                <TextField
                  label="Discount"
                  name="discount"
                  fullWidth
                  margin="dense"
                  type="number"
                  value={productData?.discount}
                  onChange={handleProductChange}
                />
              </>
            )}

            <Box
              sx={{
                mt: 2,
                display: "flex",
                gap: 2,
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                component="label"
                fullWidth
                sx={{ mt: 2 }}
              >
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={(e) => handleMultipleImagesUpload(e, "product")}
                />
                <CollectionsIcon sx={{ mr: 1 }} /> Upload Multiple Images
              </Button>

              <Button
                variant="contained"
                component="label"
                fullWidth
                sx={{ mt: 2 }}
              >
                <input type="file" hidden onChange={handleSingleImageUpload} />
                <PhotoIcon sx={{ mr: 1 }} /> Upload Single Image
              </Button>
            </Box>

            <Grid container spacing={2} marginTop={2}>
              {Multibase64?.map((image, index) => (
                <Grid item xs={4} key={index}>
                  <Box position="relative">
                    <img
                      src={image}
                      alt={`Product Image ${index}`}
                      style={{ width: "100%", height: "auto" }}
                    />

                    <IconButton
                      onClick={() => handleRemoveImage(index, "product")}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: "white",
                        backgroundColor: "black",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Grid>
              ))}
              {base64 !== null && (
                <Grid item xs={12}>
                  <Box position="relative">
                    <img
                      src={base64}
                      alt="Single Product Image"
                      style={{ width: "300px", height: "300px" }}
                    />
                    <IconButton
                      onClick={handleRemoveSingleImage}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        color: "white",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Grid>
              )}
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <button className="Deletemaster" onClick={handleClose}>
            Cancel
          </button>
          <button className="Addmaster" onClick={handleSubmit}>
            Submit
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddScreenDialog;
