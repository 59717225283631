import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./Component/Layout";
import Path from "./Common/Path";
import DashBoardScreen from "./Screens/DashBoard/DashBoardScreen";
import { useState } from "react";
import LoginScreen from "./Screens/Login/LoginScreen";
import UserData from "./Screens/User/UserData";
import CategoryMaster from "./Screens/Category/CategoryTable";
import ProductMaster from "./Screens/Product/ManageProduct/ProductTable";
import AddScreen from "./Screens/Product/ManageProduct/AddProductAndContent";

function App() {
  const [Auth, setAuth] = useState(
    localStorage.getItem("TOKEN") ? true : false
  );

  const logoutHandler = () => {
    localStorage.removeItem("TOKEN");
    setAuth(false);
  };

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path={Path.login}
            element={<LoginScreen Auth={Auth} setAuth={setAuth} />}
          />
          <Route
            path={Path.dashBoard}
            element={
              <Layout
                logoutHandler={logoutHandler}
                setAuth={setAuth}
                Auth={Auth}
                component={<DashBoardScreen />}
              />
            }
          />

          <Route
            path={Path.productShow}
            element={
              <Layout
                setAuth={setAuth}
                Auth={Auth}
                component={<ProductMaster />}
              />
            }
          />

          <Route
            path={Path.userdata}
            element={
              <Layout setAuth={setAuth} Auth={Auth} component={<UserData />} />
            }
          />

          <Route
            path={Path.Category}
            element={
              <Layout
                setAuth={setAuth}
                Auth={Auth}
                component={<CategoryMaster />}
              />
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
