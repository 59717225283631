import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import apiHelper from "../../../Common/ApiHelper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useLocation, useNavigate } from "react-router-dom";
import SnackbarComponent from "../../../Component/Snackbar/Sncakbar";
import AddScreenDialog from "./AddProductAndContent";
import { Avatar, AvatarGroup } from "@mui/material";

export default function ProductMaster() {
  const [Product, setProduct] = useState([]);
  const [operationMode, setOperationMode] = useState("add");
  const [open, setOpen] = useState(false);
  const [Dlt_SnackOpn, setDlt_SnackOpn] = useState(false);
  const [EditSnack_OP, setEditSnack_OP] = useState(false);
  const [editData, setEditData] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const EditHandler = (id) => {
    const data = Product && Product?.find((x) => x?._id == id);
    setOperationMode("edit");
    setEditData(data);
    setOpen(true);
  };

  const columns = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (cell) => {
        return (
          <>
            <div className="me-2">
              <button
                className="Addmaster me-2"
                onClick={() => {
                  EditHandler(cell?.row?._id);
                }}
                aria-label="add"
              >
                <EditIcon />
              </button>

              <button
                className="Deletemaster"
                sx={{ marginLeft: "10px" }}
                onClick={() => {
                  DeleteProduct(cell?.row?._id);
                }}
                aria-label="add"
              >
                <DeleteForeverIcon />
              </button>
            </div>
          </>
        );
      },
    },

    {
      field: "type",
      headerName: "Post Type",
      width: 140,
      valueGetter: (params) =>
        params?.row?.type == "content" ? "Content Part" : "Product Part" || "",
    },

    {
      field: "singleImage",
      headerName: " Single Image",
      width: 150,
      valueGetter: (params) => params?.row?.singleImage,
      renderCell: (params) =>
        params?.value ? (
          <img
            src={params?.value}
            alt="Product"
            style={{
              width: "50px",
              height: "50px",
              objectFit: "cover",
              borderRadius: "4px",
            }}
          />
        ) : (
          "🚫"
        ),
    },

    {
      field: "multiImages",
      headerName: "Multiple Image",
      width: 200,
      valueGetter: (params) => params?.row?.multiImages,
      renderCell: (params) => {
        const images = params?.value || [];
        return (
          <AvatarGroup max={4}>
            {images?.length > 0
              ? images?.map((imgSrc, index) => (
                  <Avatar
                    key={index}
                    alt={`Avatar ${index + 1}`}
                    src={imgSrc}
                  />
                ))
              : "🚫"}
          </AvatarGroup>
        );
      },
    },

    {
      field: "category",
      headerName: "Category",
      width: 300,
      valueGetter: (params) => params?.row?.category?.name || "",
    },

    {
      field: "title",
      headerName: "Title",
      width: 260,
    },
    {
      field: "subtitle",
      headerName: "Sub Title",
      width: 160,
    },
    {
      field: "countInStock",
      headerName: "CountInStock",
      type: "number",
      width: 100,
    },
    {
      field: "totalPrice",
      headerName: "TotalPrice",
      type: "number",
      width: 100,
    },
    {
      field: "discount",
      headerName: "Discount",
      type: "number",
      width: 100,
      valueGetter: (params) => {
        return params?.row?.discount || 0;
      },
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
    },
  ];

  console.log(Product, "Product----");

  const GetProductDetails = async () => {
    try {
      const result = await apiHelper.ProductDetails();
      if (result.status === 200) {
        setProduct(result?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetProductDetails();
    location.search === "?state?=true"
      ? setEditSnack_OP(true)
      : setEditSnack_OP(false);
  }, [open]);

  const DeleteProduct = async (id) => {
    try {
      const result = await apiHelper.DeleteProductById(id);
      if (result.status === 200) {
        // GetProductDetails();
        setDlt_SnackOpn(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="row" style={{ backgroundColor: "white" }}>
        <div className="col-12 mb-3 d-flex justify-content-between">
          <h3 className="masterTitle">Manage Product</h3>
          <button
            className="Addmaster"
            onClick={() => {
              setOpen(true);
            }}
          >
            Add New Product
          </button>
        </div>
      </div>

      {Dlt_SnackOpn ? (
        <SnackbarComponent
          setDlt_SnackOpn={setDlt_SnackOpn}
          Dlt_SnackOpn={Dlt_SnackOpn}
        />
      ) : (
        <SnackbarComponent
          EditSnack_OP={EditSnack_OP}
          setEditSnack_OP={setEditSnack_OP}
        />
      )}

      <Box sx={{ height: 400, width: "100%" }}>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={Product || []}
            columns={columns}
            getRowId={(x) => x?._id}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5, 10, 20, 50]}
            components={{
              Toolbar: GridToolbar,
            }}
            disableRowSelectionOnClick
          />
        </Box>

        <AddScreenDialog
          open={open}
          setOpen={setOpen}
          operationMode={operationMode}
          setOperationMode={setOperationMode}
          editData={editData}
        />
      </Box>
    </>
  );
}
